import { connect } from 'react-redux';
import setConversationAction, { actionSetConversationFromLogs } from '../actions/actionChat';
import createMessageAction from '../actions/actionSendChatMessage';
import createCustomerReview from '../actions/actionCustomerReview';
import getPrivatePhotos from '../actions/actionGetCharacterProductList';
import setChatNotificationPermission from '../actions/actionSetChatNotificcationPermission';
// import { getSessionMsgs } from '../actions/actionGetMsgsHistory';
import getCustomerChatLog from '../actions/actionChatLog';
import createMedia from '../actions/actionCreateNewMedia';
import { getPresenceUser } from '../actions/actionGetPresense';
// eslint-disable-next-line import/no-cycle
import Chat from '../scenes/Chat';

const getMinLength = (results) => {
  let minMessageLength = 15;
  results.forEach((result) => {
    if (result.config_name === 'MIN_CHAR_LIMIT') {
      minMessageLength = result.value;
    }
  });
  return minMessageLength;
};

const mapMinImagePurchaseCredits = (sysConfigDataRes = []) => {
  const minCreditsToPurchaseImage = sysConfigDataRes.find(result => result.config_name === 'MIN_IMAGE_PURCHASE_CREDITS') || {};
  return minCreditsToPurchaseImage.value;
};
const mapMinVideoPurchaseCredits = (sysConfigDataRes = []) => {
  const minCreditsToPurchaseVideo = sysConfigDataRes.find(result => result.config_name === 'MIN_VIDEO_PURCHASE_CREDITS') || {};
  return minCreditsToPurchaseVideo.value;
};

const mapMinAudioPurchaseCredits = (sysConfigDataRes = []) => {
  const minCreditsToPurchaseAudio = sysConfigDataRes.find(result => result.config_name === 'MIN_AUDIO_PURCHASE_CREDITS') || {};
  return minCreditsToPurchaseAudio.value;
};

const mapMinMessageToInitaiteChat = (sysConfigDataRes = []) => {
  const minMessageToInitaiteChat = sysConfigDataRes.find(result => result.config_name === 'PERF_INIT_CHAT_MIN_MSG_PAID') || {};
  return minMessageToInitaiteChat.value;
};

const mapMinReqMsgToShareMedia = (sysConfigDataRes = []) => {
  const minReqMsgToShareMedia = sysConfigDataRes.find(res => res.config_name === 'MIN_REQ_MSG_TO_SHARE_MEDIA') || {};
  return minReqMsgToShareMedia.value;
};

// const activeUser = localStorage.getItem('activeUser') ?
// JSON.parse(localStorage.getItem('activeUser')) : {};
const mapChatLogData = (results = [], activeCust) => results.map((result) => {
  // eslint-disable-next-line no-mixed-operators
  if (activeCust && result.customer_objid === activeCust.customer_objid) {
    return {
      // ...activeCust,
      id: result.session_id,
      active: result.active,
      objid: result.objid,
      createDataTime: result.create_datetime,
      totalMsg: (result.char2cust + result.cust2char),
      title: result.customer_screen_name,
      lastMsg: result.preview_message_perf,
      messages: [],
      premium: result.billing_flag,
      lastMessageTime: result.last_activity,
      unread: 0,
      custPic: result.customer_profile_pic,
      custId: result.customer_objid,
      sessionStatus: result.active ? 'on' : 'off',
      performerContinueMsgCount: 0,
      iscurrentlyActiveCust: false,
      isPremiumCustomer: result.is_premium_customer,
      sendEmailNotification: result.send_email_notification,
      customerHostReview: result.customer_host_review,
      isCustomerOnline: result.is_customer_online,
      isFavCustomer: result.is_favourite_customer,
    };
  }

  // if (!activeUser && result.session_id === activeUser.id && i === 0) {
  //   return {
  //     ...activeUser,
  //     sendEmailNotification: result.send_email_notification,
  //     sessionStatus: result.active ? 'on' : 'off',
  //     lastMsg: result.preview_message,
  //     iscurrentlyActiveCust: true,
  //   };
  // }
  return {
    id: result.session_id,
    active: result.active,
    objid: result.objid,
    createDataTime: result.create_datetime,
    totalMsg: (result.char2cust + result.cust2char),
    title: result.customer_screen_name,
    lastMsg: result.preview_message_perf,
    messages: [],
    premium: result.billing_flag,
    lastMessageTime: result.last_activity,
    unread: result.unread_messages_to_char_count,
    custPic: result.customer_profile_pic,
    custId: result.customer_objid,
    sessionStatus: result.active ? 'on' : 'off',
    performerContinueMsgCount: 0,
    iscurrentlyActiveCust: false,
    isPremiumCustomer: result.is_premium_customer,
    sendEmailNotification: result.send_email_notification,
    customerHostReview: result.customer_host_review,
    isCustomerOnline: result.is_customer_online,
    isFavCustomer: result.is_favourite_customer,
  };
});

const getDefaultMediaPrice = characterData => ({
  chatImagePrice: characterData.chat_image_price,
  chatVideoPrice: characterData.chat_video_price,
  chatAudioPrice: characterData.chat_audio_price,
});

// const mapChatMsgData = (msgs = []) => msgs.map(msg => ({
//   ...msg,
//   msgPath: msg.msg_path,
//   // createDateTime: msg.unformatted_create_datetime,
//   currentUTC: msg.unformatted_create_datetime,
//   text: msg.message_txt,
//   media: msg.media,
//   objid: msg.objid,
//   status: 'read',
//   chat_status: msg.msg_path === 'Char2Cust' ? msg.delivery_status : '',
//   type: msg.msg_path,
//   isPremiumChat: !(msg.billing_status === 'NonBillable'),
//   direction: msg.msg_path === 'Char2Cust' ? 'out' : 'in',
// }));

const setCustomerSessionData = (
  dispatch,
  conversations,
  promise,
  getApiStatus, isFromChatlog = false, callAPIFunc = null,
) => {
  getApiStatus(true);
  promise
    .then((payload) => {
      if (isFromChatlog && callAPIFunc !== null) {
        dispatch(
          actionSetConversationFromLogs(
            mapChatLogData(payload.data.results), isFromChatlog, callAPIFunc,
          ),
        );
        getApiStatus(false);
      } else {
        dispatch(setConversationAction(
          mapChatLogData(payload.data.results, payload.data.results[0]) || [],
        ));
        getApiStatus(false);
      }
    })
    .catch((err) => {
      getApiStatus(false);
      console.log(err);
    });
};

// export const updateConversationWithMsg = (
//   dispatch,
//   newActiveUserId,
//   conversations,
//   promise,
// ) => promise
//   .then((payload) => {
//     const msgs = mapChatMsgData(payload.data.results);
//     const currentSession = conversations.find(conv => conv.id === newActiveUserId);
//     let updatedMsgs = [];
//     if (msgs.length) {
//       updatedMsgs = [...currentSession.messages, ...msgs];
//       const updatedSession = {
//         ...currentSession,
//         messages: updatedMsgs,
//       };
//       const updatedConversation = conversations.map((conv) => {
//         if (conv.id === updatedSession.id) {
//           return updatedSession;
//         }
//         return conv;
//       });
//       dispatch(setConversationAction(updatedConversation));
//     }
//     return payload.data.results;
//   })
//   .catch(err => console.log(err));


const mapStateToProps = (state) => {
  const { login: { data: { xmpp_user: jid, perfcharacter = [] } = {} } } = state;
  const { performer: { data: { characters = {} } = {} } } = state;
  const { mobileLogin: { mobileData: { mobileUser = '', charObjID = 0 } = {} } } = state;
  /* eslint-disable */
  const { productList: { data: { results: privateProductList = [], next = null, count = 0 } = {} } = {} } = state;
  /* eslint- enable */
  const { chat: { conv = [] } } = state;
  const { sysConfig: { data: { results = [] } = {} } = {} } = state;
  const { presence: { data: { chatStatus: isActiveForChat } = {} } = {} } = state;
  const {
    chatLog: {
      data: { results: sessionData = [] } = {}, isLoading: chatLogLoader = false,
    } = {},
  } = state;
  const minCharLength = getMinLength(results);
  // const stringifyConv = JSON.stringify(conv);
  const charObjid = (perfcharacter.length > 0 && perfcharacter[0].objid) || 0;
  const isTier2 = (perfcharacter.length && perfcharacter[0].is_tier2) || false;
  

  const mobileLocalMediaDefaultPrices = JSON.parse(localStorage.getItem('mobileDefaultMediaPrices'));
  // console.log('mobileLocalMediaDefaultPrices', mobileLocalMediaDefaultPrices);

  let defaultMediaPrice = {};
  if (characters.length > 0) {
    defaultMediaPrice = getDefaultMediaPrice(characters[0]);
  }
  if (mobileLocalMediaDefaultPrices) {
    defaultMediaPrice = getDefaultMediaPrice(mobileLocalMediaDefaultPrices);
  }

  const chatValidationData = {
    minCharLength,
    isActiveForChat,
  };

  const localData = JSON.parse(localStorage.getItem('mobileChatData'));
 
  if (jid) {
    return ({
      performerMediaPrices:defaultMediaPrice,
      minImagePurchaseCredits: mapMinImagePurchaseCredits(results),
      minVideoPurchaseCredits: mapMinVideoPurchaseCredits(results),
      minAudioPurchaseCredits: mapMinAudioPurchaseCredits(results),
      conversations: conv,
      jid,
      charObjid,
      chatValidationData,
      privateProductList,
      next,
      count,
      chatSessionData: mapChatLogData(sessionData),
      chatLogLoader,
      minMessageToInitaiteChat: mapMinMessageToInitaiteChat(results),
      isTier2,
      minReqMsgToShareMedia: mapMinReqMsgToShareMedia(results),
    });
  }

  return ({
    performerMediaPrices: defaultMediaPrice,
    minImagePurchaseCredits: mapMinImagePurchaseCredits(results),
    minVideoPurchaseCredits: mapMinVideoPurchaseCredits(results),
    minAudioPurchaseCredits: mapMinAudioPurchaseCredits(results),
    conversations: conv,
    jid: mobileUser || localData.xmpp_user,
    charObjid: charObjID || localData.character_objid,
    chatValidationData,
    privateProductList,
    next,
    count,
    chatSessionData: mapChatLogData(sessionData),
    chatLogLoader,
    minMessageToInitaiteChat: mapMinMessageToInitaiteChat(results),
    isTier2,
    minReqMsgToShareMedia: mapMinReqMsgToShareMedia(results),
  });
};

const mapDispatchToProps = dispatch => ({
  createMessageFunction: data => dispatch(createMessageAction(data)),
  updateConversationInRedux: data => dispatch(setConversationAction(data)),
  getPrivatePhotos: url => dispatch(getPrivatePhotos(url)),
  setChatNotificationPermission: data => dispatch(setChatNotificationPermission(data)),
  createNewMedia: data => dispatch(createMedia(data)),
  getPresenceUser: url => dispatch(getPresenceUser(url)),
  getCustomerChatLog: (url, conversations, getApiStatus, fromLogsPage = false, callbackFunc = null) => setCustomerSessionData(
    dispatch,
    conversations,
    getCustomerChatLog(url).payload,
    getApiStatus,
    fromLogsPage,
    callbackFunc
  ),
  // getSessionMsgs: (url, newActiveUserId, conversations) => updateConversationWithMsg(
  //   dispatch,
  //   newActiveUserId,
  //   conversations,
  //   getSessionMsgs(url).payload,
  // ),
  getSessionMsgs: (url, newActiveUserId, conversations) => updateConversationWithMsg(
    dispatch,
    newActiveUserId,
    conversations,
    results,
  ),
  checkActiveSessionAvailable: url => getCustomerChatLog(url).payload,
  createCustomerReviewFun: data => dispatch(createCustomerReview(data)),
});

const ChatContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Chat);

export default ChatContainer;
