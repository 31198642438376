import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import api from '../../actions/api';
import './style.scss';
// import custoimglg from '../../assets/images/customer-img-lg.png';
// import custoimgmd from '../../assets/images/customer-img-md.png';
// import custoimgsm from '../../assets/images/customer-img-sm.png';
import circleSearch from '../../assets/images/circle-search.svg';
import LoadingSpinner from '../../utils/LoadingSpinner';
import crossIcon from '../../assets/images/cross-icon.svg';
import CustomModal from '../../components/CustomModal';

const Roulette = (props) => {
  const chatPresenceData = localStorage.getItem('chatPresence');

  const {
    objid: charId,
    handleRoulette,
    isRouletteEnable: rouletteEnable,
    isHeartBeatApiOn,
    updateStatus,
    rouletteHeartbeat,
    currentTimeConst,
    history,
    isLoading,
    chatStatus,
    phoneStatus,
    videoStatus,
    updatePresence,
    notAvailableTimer,
    hearbeatTimeoutConfig,
  } = props;

  // const [isDisableRoulette, setIsDisableRoulette] = useState(false);
  // const [timeoutConstant, settimeoutConstant] = useState(0);
  // const [locationKeys, setLocationKeys] = useState(history.location.pathname);
  // const urlsHistory = history;
  const [performerIdle, setperformerIdle] = useState(false);
  const [isMediaDevicePermissionAllowed, setisMediaDevicePermissionAllowed] = useState(true);
  const [isHostNotAvailable, setIsHostNotAvailable] = useState(false);

  const [jwtToken, setJwtToken] = useState('');

  const [chatPresence, setChatPresence] = useState(chatPresenceData);
  const [isChatCallWarningModalOpen, setIsChatCallWarningModalOpen] = useState(false);
  const [banPerformerMessage, setPerformerBanMessage] = useState('');
  const [isQrRefreshed, setIsQrRefreshed] = useState(false);
  const [heartbeatApiTimer, setHeartbeatApiTimer] = useState(null);
  const [localStream, setLocalStream] = useState('');
  const mediaStream = useRef(null);

  const mediaPermission = () => navigator.mediaDevices.getUserMedia({
    video: true,
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
    },
  });

  let tConst = null;
  const disableRoulette = async () => {
    // Prepare the payload for updateStatus
    const payload = {
      action: 'presence',
      char_objid: charId,
      presence: 'roulette',
      state: 'remove',
    };

    // Update status and handle UI changes
    updateStatus(payload).then(() => {
      clearInterval(isHeartBeatApiOn);
      clearInterval(currentTimeConst);
      const rouletteData = {
        isRouletteEnable: false,
        isHeartBeatApiOn: 0,
        currentTimeConst: 0,
        notAvailableTimer: 0,
      };
      handleRoulette(rouletteData);
      localStorage.removeItem('videoChat');
      localStorage.removeItem('chatPresence');
      // Stop mediaStream if it exists
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach(track => track.stop());
        mediaStream.current = null;
      }
      history.push('/dashboard');
    });

    // Stop localStream if it exists
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
      setLocalStream(null);

      const ownVideoElement = document.getElementById('selfPreviewStream');
      if (ownVideoElement && 'srcObject' in ownVideoElement) {
        ownVideoElement.srcObject = null;
      }
    }
  };

  useEffect(() => () => {
    // Stop mediaStream if it exists
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach(track => track.stop());
      mediaStream.current = null;
    }
  }, []);

  const handleClickOnContinue = () => {
    const payload = {
      action: 'presence',
      char_objid: charId,
      presence: 'voice',
      state: 'offline',
    };
    updateStatus(payload).then(() => {
      const presenceObj = {
        chatStatus,
        phoneStatus: false,
        videoStatus,
      };
      updatePresence(presenceObj);
    });
    localStorage.removeItem('chatPresence');
    history.push('/dashboard');
  };

  const turnOffChatPresence = () => {
    const payload = {
      action: 'presence',
      char_objid: charId,
      presence: 'sms',
      state: 'offline',
    };
    updateStatus(payload).then(() => {
      const presenceObj = {
        chatStatus: false,
        phoneStatus,
        videoStatus,
      };
      updatePresence(presenceObj);
    });
    setChatPresence(false);
    localStorage.removeItem('chatPresence');
  };

  useEffect(() => {
    if (chatStatus === true) {
      localStorage.setItem('chatPresence', chatStatus);
      setChatPresence(true);
    }
  }, [chatStatus]);


  useEffect(() => {
    mediaPermission()
      .then((stream) => {
        setLocalStream(stream);
        mediaStream.current = stream;
        setisMediaDevicePermissionAllowed(true);
        const ownVideoElement = document.getElementById('selfPreviewStream');
        ownVideoElement.muted = true; // Prevent microphone audio feedback
        if ('srcObject' in ownVideoElement) {
          ownVideoElement.srcObject = stream;
        } else {
          // fallback
          ownVideoElement.src = URL.createObjectURL(stream);
        }
      })
      .catch(() => {
        setisMediaDevicePermissionAllowed(false);
      });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const payload = {
      action: 'presence',
      char_objid: charId,
      presence: 'roulette',
      state: 'add',
    };
    if (isMediaDevicePermissionAllowed) {
      clearInterval(currentTimeConst);
      clearInterval(notAvailableTimer);
      updateStatus(payload).then(() => {
        api.get('/get-jwt-auth-token/')
          .then((response) => {
            const { Token: resToken = '' } = response.data;
            // console.log(response.data.Token);
            setJwtToken(resToken);
            // console.log(jwtToken);
          })
          .catch(error => console.log(error));
        const toutConst = setTimeout(() => {
          setperformerIdle(true);
        }, 300000);

        // --- No host available handling starts ---
        // Timeout for displaying Not available message
        // and stopping heartbeat api
        // will run after hearbeatTimeoutConfig seconds

        const noHostFoundTime = setTimeout(() => {
          setperformerIdle(false);
          setIsHostNotAvailable(true);
        }, hearbeatTimeoutConfig * 1000);
        // --- No host available handling ends ---

        const heartBeatApi = isHeartBeatApiOn || 0;
        const rouletteData = {
          isRouletteEnable: true,
          isHeartBeatApiOn: heartBeatApi,
          currentTimeConst: toutConst,
          notAvailableTimer: noHostFoundTime,
        };
        handleRoulette(rouletteData);
      })
        .catch((error) => {
          const rouletteData = {
            isRouletteEnable: false,
            isHeartBeatApiOn,
            currentTimeConst,
            notAvailableTimer,
          };
          handleRoulette(rouletteData);
          if (error.response
            && ((error.response.status === 400)
            || (error.response.status === 401))) {
            setPerformerBanMessage(error.response.data.result);
            setIsChatCallWarningModalOpen(true);
          }
        });
    }
  }, [isMediaDevicePermissionAllowed]);

  useEffect(() => {
    if (isHostNotAvailable) {
      clearInterval(tConst);
      clearInterval(heartbeatApiTimer);
      tConst = null;
      setHeartbeatApiTimer(null);
      const rouletteData = {
        isRouletteEnable: false,
        isHeartBeatApiOn: 0,
        currentTimeConst,
        notAvailableTimer,
      };
      handleRoulette(rouletteData);
    }
  }, [isHostNotAvailable]);

  // Refresh QR Code
  const RefreshQRCodeHandler = () => {
    setIsQrRefreshed(true);
    api.get('/get-jwt-auth-token/')
      .then((response) => {
        const { Token: resToken = '' } = response.data;
        setJwtToken(resToken);
        setIsQrRefreshed(false);
      })
      .catch((error) => {
        console.log(error);
        setIsQrRefreshed(false);
      });
  };

  useEffect(() => {
    if (rouletteEnable && isHeartBeatApiOn === 0) {
      const data = {
        character_id: charId,
      };

      tConst = setInterval(() => {
        rouletteHeartbeat(data);
      }, 30000);
      setHeartbeatApiTimer(tConst);
      const rouletteData = {
        isRouletteEnable: true,
        isHeartBeatApiOn: tConst,
        currentTimeConst,
        notAvailableTimer,
      };
      handleRoulette(rouletteData);
    }
  }, [rouletteEnable]);

  // handle close tab disable for roulette call
  // window.addEventListener('beforeunload', () => {
  //   disableRoulette();
  // });
  // handle click on back button, disable for roulette call
  // window.addEventListener('hashchange', (e) => {
  //   console.log('inhash change=======', e.oldURL, e.newURL, e);
  //   // disableRoulette();
  // });

  const setText = () => {
    if (!isMediaDevicePermissionAllowed) {
      return (
        <h3 className="text-center text-white  info-text roulette-call-msg">
          It seems you did not allow
          <br />
          camera or microphone.
        </h3>
      );
    }
    if (performerIdle) {
      return (
        <h3 className="text-center text-white info-text roulette-call-msg">
          You are online on roulette.
          The call will get connected automatically when a customer gets in.
        </h3>
      );
    }
    if (isHostNotAvailable) {
      return (
        <h3 className="text-center text-white info-text roulette-call-msg">
          It seems that no customers are available for roulette call.
          <br />
          Please try again after some time.
        </h3>
      );
    }
    return (
      <h3 className="text-center text-white info-text searchingText roulette-call-msg">
        Searching for next available Customer
        <br />
        Please Stand By
      </h3>
    );
  };
  /* eslint-disable */

  const renderRouletteBanedPerformerModal = () => (
    <CustomModal
      header=""
      body={banPerformerMessage}
      buttonText="Ok"
      // footer="Dismiss"
      // footerLink={() => setIsChatCallWarningModalOpen(false)}
      open={isChatCallWarningModalOpen}
      onClick={handleClickOnContinue}
      toggle={() => setIsChatCallWarningModalOpen(false)}
      displayNone="none"
    />
  );

  const SearchScreen = () => {
    if (isLoading) {
      return (
        <LoadingSpinner />
      );
    }

    return (
      <>
        {renderRouletteBanedPerformerModal()}
        <div className={chatPresence ? 'roullette_wrapper QRcodeOn' : 'roullette_wrapper'}>
          <div className='roullette_call_box'>
            <section className="self_preview_video">
              <video className="hostEndVideo" id="selfPreviewStream" autoPlay playsInline muted/>
            </section>
            <div className="outerCircle">
              <div className="innerCircle">
                <div className="innerCircleSmall">
                  <span id="sun" className={isMediaDevicePermissionAllowed && !isHostNotAvailable ? "fa fa-search marker" : "fa fa-search marker pulses-none"}>
                    <img src={circleSearch}/>
                  </span>
                </div>
              </div>
            </div>
            {setText()}
          </div>
          {chatPresence ? <div className='roullette_qr_code_box'>
            <div className='qr_code_content'>
              <div className='dr_code_container'>
                <QRCode
                // value={`http://127.0.0.1:8000/#/continue-to-chat/${jwtToken}`}
                value={`${window.location.origin}/#/continue-to-chat/${jwtToken}`}
                size={200}
                className='qr_canvas'
                />
                {isQrRefreshed ? <div className='refresh-loader'>
                  <i class="fa fa-spinner fa-spin"></i>
                </div> : ''}
              </div>
              <div className='text-center'>
                <button onClick={RefreshQRCodeHandler} className='btn btn-primary text-white' disabled={isQrRefreshed} style={{ fontSize: '14px' }}>
                  <i class="fa fa-refresh mr-1" aria-hidden="true"></i>
                  Refresh QR Code
                </button>
              </div>
              <div className='mt-3 qrcode_text'>
                <span>Scan QR code to continue doing chat from your mobile
                </span>
                <span> device while being available for roulette on desktop.
                </span>
              </div>
              <div className='separater_or mt-2 mb-2 text-primary'>Or</div>
              <div className='turnoff_chat_availability'>
                Turn off your chat availability.
                <span role="presentation" onClick={turnOffChatPresence}>Turn Off</span>
              </div>  
            </div>
            <div className='qr_bottom_note'>
              <p>Note: Photos/Videos shared from desktop do not get sync on mobile and vice versa.</p>  
            </div>
          </div> : ''}
        </div>

        <div className="disable-roulette-btn-wrap">
          <button className="close-btn" type="button" onClick={disableRoulette}>
            <img className="cross-icon" src={crossIcon} alt="cross-icon" />
          </button>
        </div>
        
      </>  
    )
  };

  return (
    <>
      {SearchScreen()}
    </>
  );
  /* eslint-enable */
};

Roulette.defaultProps = {
  isRouletteEnable: false,
  objid: 0,
  history: {},
  isLoading: false,
  isHeartBeatApiOn: 0,
  currentTimeConst: 0,
  chatStatus: false,
  phoneStatus: false,
  videoStatus: false,
  notAvailableTimer: 0,
  hearbeatTimeoutConfig: 1800,
};

Roulette.propTypes = {
  handleRoulette: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  isRouletteEnable: PropTypes.bool,
  currentTimeConst: PropTypes.number,
  rouletteHeartbeat: PropTypes.func.isRequired,
  objid: PropTypes.number,
  history: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  isHeartBeatApiOn: PropTypes.number,
  updatePresence: PropTypes.func.isRequired,
  chatStatus: PropTypes.bool,
  phoneStatus: PropTypes.bool,
  videoStatus: PropTypes.bool,
  notAvailableTimer: PropTypes.number,
  hearbeatTimeoutConfig: PropTypes.number,
};

export default Roulette;
