import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import sandTimeLogo from '../../../assets/images/sandTime.png';

const UnderReviewPopupAskMeAny = ({ open, closeUnderReviewPopup, askMeAnythingBonusCredits }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(open);
    closeUnderReviewPopup();
  }, [open]);

  const toggle = () => setModal(!modal);

  return (
    <Modal className="ar-under-review-popup" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <img src={sandTimeLogo} alt="ask-me-anything-logo" />
        <h3>Under Review!</h3>
        <p>
          Your answer is under review. Once approved, you will receive &nbsp;
          {askMeAnythingBonusCredits}
          &nbsp;Credits.
        </p>
      </ModalBody>
    </Modal>
  );
};

UnderReviewPopupAskMeAny.defaultProps = {
  open: false,
  askMeAnythingBonusCredits: '',
};
UnderReviewPopupAskMeAny.propTypes = {
  open: PropTypes.bool,
  closeUnderReviewPopup: PropTypes.func.isRequired,
  askMeAnythingBonusCredits: PropTypes.string,
};

export default UnderReviewPopupAskMeAny;
