const unansweredCountReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'GET_ASKMEANYTHING_UNANSWER_COUNTS_SUCCESS': {
      return {
        ...state,
        data: action.payload?.answerable_question_count,
      };
    }
    case 'INCREMENT_ASKMEANYTHING_UNANSWER_COUNT': {
      return {
        ...state,
        data: state?.data ? state?.data + 1 : 1,
      };
    }
    case 'DECREMENT_ASKMEANYTHING_UNANSWER_COUNT': {
      return {
        ...state,
        data: state?.data ? state?.data - 1 : 1,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default unansweredCountReducer;
