import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../../components/Pagination';

const QuestionsList = ({
  questions, onAnswerClicked, onSubmittedQueClick, formatDateTimeFun,
  questionCount, setCurrentPage, currentPage,
}) => {
  const onAnswerSelectHandler = (ques) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onAnswerClicked(ques);
  };

  const onAnsSubmittedQuesHanlder = (subQes) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onSubmittedQueClick(subQes);
  };

  const handleQuestionsListPaginations = (pageNumer) => {
    setCurrentPage(pageNumer);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const statusColorsObj = {
    submitted: 'bg-yellow-light',
    approved: 'bg-green-light',
    modified_approved: 'bg-green-light',
    inactive: 'bg-green-light',
    rejected: 'bg-red-light',
  };

  return (
    <>
      {questions.map(q => (
        q.is_visible ? (
          <div key={q.objid} className="ask-me-anything-question-lists" onClick={q.is_answered ? () => onAnsSubmittedQuesHanlder(q) : ''} role="presentation" style={{ cursor: q.is_answered ? 'pointer' : 'auto' }}>
            <div className="ask-me-anything-questions">
              <p className="ask-me-anything-question-txt">{q.question_text}</p>
              <span className="ask-me-anything-date">{formatDateTimeFun(q.created_datetime)}</span>
            </div>
            <div className="ask-me-anything-action">
              {q.is_answered ? <button type="button" className={`ar-ask-me-anything-submitted-btn ${statusColorsObj[q.answer_status]}`}>{q.answer_status}</button> : <button type="button" className="ar-ask-me-anything-answer-btn" onClick={() => onAnswerSelectHandler(q)}>Answer</button>}
            </div>
          </div>
        ) : ''
      ))}
      {questionCount > 25
      && (
        <div className="pull-right">
          <Pagination
            totalCount={questionCount}
            pageSize={25}
            onPageClick={handleQuestionsListPaginations}
            currentPage={currentPage}
          />
        </div>
      )
      }
    </>
  );
};

QuestionsList.defaultProps = {
  questions: [],
  questionCount: 0,
  currentPage: 1,
};
QuestionsList.propTypes = {
  questions: PropTypes.arrayOf(),
  onAnswerClicked: PropTypes.func.isRequired,
  onSubmittedQueClick: PropTypes.func.isRequired,
  formatDateTimeFun: PropTypes.func.isRequired,
  questionCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
};

export default QuestionsList;
