import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getPresenceUser } from '../actions/actionGetPresense';
import Sidebar from '../components/Sidebar';

const getTotalUnreadMessageCount = (conv) => {
  let unreadCount = 0;
  conv.forEach((cust) => {
    unreadCount += cust.unread;
  });
  return unreadCount;
};

export const mapStateToProps = (state) => {
  const {
    login: { data: { perfcharacter = [{}] } = {} } = {},
    presence: { data: sidebarData = {} } = {},
    chat: {
      conv = [],
    } = {},
  } = state;
  const charProfileImage = perfcharacter[0]?.primary_pic_url || '';
  const charId = perfcharacter[0]?.objid || 0;
  const name = perfcharacter[0]?.display_name || '';
  const totalUnreadMessge = getTotalUnreadMessageCount(conv) || 0;
  const characterClass = perfcharacter[0]?.character_class || '';

  const updatedSidebarData = {
    ...sidebarData,
    totalUnreadMessge,
    charProfileImage,
    charId,
    name,
    characterClass,
  };
  return ({
    data: updatedSidebarData,
  });
};

export const mapDispatchToProps = dispatch => ({
  getPresenceUser: data => dispatch(getPresenceUser(data)),
});

const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);

export default withRouter(SidebarContainer);
