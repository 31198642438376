import api from './api';

const getAskMeAnyQuestions = quesData => ({
  type: 'GET_ASK_ME_ANY_QUESTIONS',
  payload: api.get(quesData),
});

export const createAskMeAnyAnswers = ansData => ({
  type: 'CREATE_ASK_ME_ANY_ANSWERS',
  payload: api.post('/cstanswers/create/', ansData),
});

export const createAskMeAnyAnswerMedia = mediaData => ({
  type: 'CREATE_ASK_ME_ANY_ANS_MEDIA',
  payload: api.post('/cstquestionanswermedia/create/', mediaData),
});


export const getAskMeAnyUnansweredCount = charID => async (dispatch) => {
  try {
    const { data } = await api.get(`/cstquestions/unanswered_question_count/?character_objid=${charID}`);
    dispatch({
      type: 'GET_ASKMEANYTHING_UNANSWER_COUNTS_SUCCESS',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'GET_ASKMEANYTHING_UNANSWER_COUNTS_FAILED',
      payload: error?.response?.data,
    });
  }
};

export const incrementAskMeAnyUnanswerCount = data => ({
  type: 'INCREMENT_ASKMEANYTHING_UNANSWER_COUNT',
  payload: data,
});

export const decrementAskMeAnyUnanswerCount = data => ({
  type: 'DECREMENT_ASKMEANYTHING_UNANSWER_COUNT',
  payload: data,
});

export default getAskMeAnyQuestions;
