import { connect } from 'react-redux';
import AskMeAnything from '../scenes/AskMeAnything';
import getAskMeAnyQuestions, { createAskMeAnyAnswers, createAskMeAnyAnswerMedia } from '../actions/actionAskMeAnything';

const mapAskMeAnythingBonusCredits = (sysConfigDataRes = []) => {
  const askMeAnythingBonusCredits = sysConfigDataRes.find(bonuscredit => bonuscredit.config_name === 'AMA_BONUS_CREDITS') || {};
  return askMeAnythingBonusCredits.value;
};

const mapAskMeAnythingBonusAnswerLimit = (sysConfigDataRes = []) => {
  const askMeAnythingBonusAnswerLimit = sysConfigDataRes.find(anslimit => anslimit.config_name === 'AMA_BONUS_ANSWER_LIMIT') || {};
  return askMeAnythingBonusAnswerLimit.value;
};

const mapStateToProps = (state) => {
  const {
    performer: { data: { characters = {} } = {} } = {},
  } = state;

  const { sysConfig: { data: { results = [] } = {} } = {} } = state;

  let perfCharacterObjid = '';
  if (characters.length > 0) {
    perfCharacterObjid = characters[0].objid;
  }
  return ({
    perfCharacterObjid,
    askMeAnythingBonusCredits: mapAskMeAnythingBonusCredits(results),
    askMeAnythingBonusAnswerLimit: mapAskMeAnythingBonusAnswerLimit(results),
  });
};

const mapDispatchToProps = dispatch => ({
  getAskMeAnyQuestions: data => dispatch(getAskMeAnyQuestions(data)),
  createAskMeAnyAns: data => dispatch(createAskMeAnyAnswers(data)),
  createAskMeAnyAnswerMedia: data => dispatch(createAskMeAnyAnswerMedia(data)),
});

const AskMeAnythingContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AskMeAnything);

export default AskMeAnythingContainer;
