import { connect } from 'react-redux';
import EngagementScore from '../scenes/Dashboard/components/EngagementScore';
import engagmentScoreAction from '../actions/actionEngagmenstScore';

const mapMaxSessionCountEngagment = (config) => {
  const maxSessionCountEngagments = config.find(item => item.config_name === 'MIN_SESSION_COUNT_ENGAGEMENT') || {};
  return maxSessionCountEngagments.value;
};

const mapMinMsgCountEngagement = (config) => {
  const minMsgCountEngagements = config.find(item => item.config_name === 'MIN_MSGS_COUNT_ENGAGEMENT') || {};
  return minMsgCountEngagements.value;
};

const mapStateToProps = (state) => {
  const {
    login: { data: { perfcharacter = [{}] } = {} } = {},
    sysConfig: { data: { results = [] } = {} } = {},
  } = state;
  const charObjId = perfcharacter[0]?.objid || 0;
  return {
    charObjId,
    maxSessionCountEngagment: mapMaxSessionCountEngagment(results),
    minMsgCountEngagement: mapMinMsgCountEngagement(results),
  };
};

const mapDispatchToProps = dispatch => ({
  engagmentScoreFunction: data => dispatch(engagmentScoreAction(data)),
});

const EngagementScoreContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EngagementScore);

export default EngagementScoreContainer;
