import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../actions/api';
import LoadingSpinner from '../../../utils/LoadingSpinner';

const ViewSubmitedAnswer = ({ submittedAns, onCancelSubmittedAnswer, formatDateTimeFun }) => {
  const [answers, setAnswers] = useState([]);
  const [isAnsLoading, setIsAnsLoading] = useState(false);

  useEffect(() => {
    setIsAnsLoading(true);
    api.get(`/cstanswers/get/${submittedAns && submittedAns.answer_objid}/`)
      .then((ansResp) => {
        const { data } = ansResp;
        setIsAnsLoading(false);
        setAnswers(data);
      }).catch(err => console.log(err));
  }, []);

  const allAnsMedias = answers && answers.answer_medias && answers.answer_medias.map((media) => {
    console.log('');
    return (
      <div key={media.objid} className="answers-medias">
        <img src={media.media_url} alt="ansmedia" />
      </div>
    );
  });

  return (
    <>
      <div className="ask-me-anything-submited-answer-box">
        <p className="ask-me-anything-question-txt">{submittedAns.question_text}</p>
        <span className="ask-me-anything-question-date">{formatDateTimeFun(submittedAns.created_datetime)}</span>
        {isAnsLoading ? <div><LoadingSpinner /></div>
          : (
            <>
              <p className="ask-me-anything-answer-txt">{answers.answer_text}</p>
              {answers && answers.answer_medias && answers.answer_medias.length > 0
              && <div className="all-ans-media-list">{allAnsMedias}</div>
              }
              <button type="button" className="ask-me-anything-outline-btn" onClick={() => onCancelSubmittedAnswer()}>Cancel</button>
            </>
          )
        }
      </div>
    </>
  );
};

ViewSubmitedAnswer.defaultProps = {
  submittedAns: null,
};

ViewSubmitedAnswer.propTypes = {
  submittedAns: PropTypes.instanceOf(Object),
  onCancelSubmittedAnswer: PropTypes.func.isRequired,
  formatDateTimeFun: PropTypes.func.isRequired,
};

export default ViewSubmitedAnswer;
