/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-spacing */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import api from '../../actions/api';

import CustomerList from './components/CustomerList';
import AllConversation from './components/Conversation';
import CustomModal from '../../components/CustomModal/index';
import ChatInitiateBlockModal from './components/ChatInitiateBlockModal';
// import { isUserAgentMobile } from '../../utils/helpers';
import './style.scss';
import ChatInitiatePrimiumModal from './components/ChatInitiatePrimiumModal';
import polling from '../../utils/polling';
// eslint-disable-next-line import/no-cycle
import {getPastChats, setCurrentPage} from '../../actions/actionGetMsgsHistory';
import { debounce, mapChatLogData } from '../../utils/ChatHelpers';
import LoadingSpinner from '../../utils/LoadingSpinner';

let favoToastID;
const Chat = (props) => {
  const {
    conversations,
    jid,
    charObjid,
    createMessageFunction,
    updateConversationInRedux,
    chatValidationData,
    getPrivatePhotos,
    privateProductList,
    next,
    count,
    setChatNotificationPermission,
    getCustomerChatLog,
    createNewMedia,
    minImagePurchaseCredits,
    minVideoPurchaseCredits,
    minAudioPurchaseCredits,
    performerMediaPrices,
    getPresenceUser,
    checkActiveSessionAvailable,
    minMessageToInitaiteChat,
    // isTier2,
    createCustomerReviewFun,
    // eslint-disable-next-line react/prop-types
    location,
    // eslint-disable-next-line react/prop-types
    history,
    minReqMsgToShareMedia,
  } = props;

  const [newActiveUserId, setNewActiveUserId] = useState(null);
  const [activeUser, setactiveUser] = useState({});
  const [pageSize, setPageSize] = useState(1);
  const [chatMsgLoading, setChatMsgLoading] = useState(false);
  const [videoStatuLoading, setVideoStatuLoading] = useState(false);
  const [loadingListApi, setLoadingListApi] = useState(false);
  const [isResumeModalshow, setIsResumeModalShow] = useState(false);
  const [isChatInitiateBlockModalShow, setIsChatInitiateBlockModalShow] = useState(false);
  const [isChatInitiatePrimiunModalShow, setIsChatInitiatePrimiumModalShow] = useState(false);
  const [chatShow, setChatShow] = useState(false);
  const [disableResumeChat, setDisableResumeChat] = useState(false);
  // const [isResumeChatResults, setIsResumeChatResults] = useState(false);
  const [chatMediaPhotosState, getChatMediaPhotosState] = useState([]);
  const [chatMediaVideosState, getChatMediaVideosState] = useState([]);
  const [isRoomChanged, setIsRoomChanged] = useState({});
  // MMN Pagination related States Starts
  // const [customersList, setCustomersList] = useState([]);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  // Pagination related States Ends

  const [uniqueCustomersID, setUniqueCustomersID] = useState([]);
  const [updatedOnlineStatus, setUpdatedOnlineStatus] = useState([]);
  const [runOnlyOnce, setRunOnlyOnce] = useState(false);
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [activeSessionID, setActiveSessionID] = useState(0);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  // eslint-disable-next-line max-len
  const [activeUserOnlineStatus, setActiveUserOnlineStatus] = useState(activeUser?.isCustomerOnline);
  const [isProgress, setIsProgress] = useState(0);
  const [chatLogsLoading, setChatLogsLoading] = useState(false);
  const [cameFromLogsPage, setCameFromLogsPage] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // eslint-disable-next-line react/prop-types
  //   const unlisten = props.history.listen((locations) => {
  //     if (locations.pathname !== '/chat') {
  //       updateConversationInRedux([]);
  //       setactiveUser({});
  //     }
  //   });

  //   return () => {
  //     console.log('ehat?');
  //     unlisten();
  //   };

  // // eslint-disable-next-line react/prop-types, react/destructuring-assignment
  // }, [props.history, setactiveUser]);

  const {
    // eslint-disable-next-line no-unused-vars
    data: conversationss, currentPage, totalCount, loading,
  } = useSelector(state => state.pastChats);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const interval = setInterval(() => {
      const randomIncrement = Math.floor(Math.random() * 10) + 1;
      setIsProgress(prevCount => prevCount + randomIncrement);
    }, 700);

    if (isProgress >= 90) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isProgress]);

  const createNewChatRoom = (updatedConv = [], chatData) => {
    const newSession = {
      id: 0,
      active: true,
      custId: chatData.customerObjid,
      title: chatData.customerName,
      messages: [],
      lastMsg: 'No Conversations Yet',
      premium: 0,
      unread: 0,
      iscurrentlyActiveCust: true,
      custPic: chatData.customerProfilePic,
      sessionStatus: 'pending',
      customerchanneltype: '',
      performerContinueMsgCount: 0,
      lastMessageTime: new Date(),
      isPremiumCustomer: chatData.isPremiumCustomer,
      customerHostReview: chatData.customerHostReview,
      isCustomerOnline: chatData.isCustomerOnline,
      sendEmailNotification: chatData.sendEmailNotification,
      isFavCustomer: chatData.is_favourite_customer,
    };
    const updatedConversation = mapChatLogData(updatedConv.map(conv => ({
      ...conv,
      iscurrentlyActiveCust: false,
    })));
    // const uniqueConversations = updatedConversation.filter(el => (el.custId === chatData ? newSession : el));
    // updatedConversation.unshift(newSession);
    // updateConversationInRedux(uniqueConversations);
    // setactiveUser(conversations[0]);


    const uniqueConversations = updatedConversation.find(el => el.custId === chatData.customerObjid);
    // const uniqueConversationIndex = updatedConversation.findIndex(el => el.custId === chatData.custId);
    if (uniqueConversations === -1 || !uniqueConversations) {
      updatedConversation.unshift({...newSession, iscurrentlyActiveCust: true});
      updateConversationInRedux(updatedConversation);
    } else {
      const newConversations = updatedConversation.filter(el => el.custId !== chatData.customerObjid);
      newConversations.unshift({...uniqueConversations, iscurrentlyActiveCust: true});
      updateConversationInRedux(newConversations);
    }
  };

  useEffect(() => {
    // const id = location.state?.id;
    // Check if user came from logs page
    if (location.state && location.state.fromLogsPage) {
      setCameFromLogsPage(true);
    }
  }, [location]);

  useEffect(() => {
    if (cameFromLogsPage) {
      setChatLogsLoading(true);
      // setactiveUser({});
    }
  }, [cameFromLogsPage]);

  useEffect(() => {
    let allCustomersId = [];
    if (conversations && conversations.length) {
      allCustomersId = conversations.map(({ custId }) => custId);
      setUniqueCustomersID([...new Set(allCustomersId)]);
    }
  }, [conversations]);

  // useEffect(() => {
  //   if (newActiveUserId) {
  //     const currentSession = conversations.find(conv => conv.custId === newActiveUserId.custId);
  //     const updatedMsgs = [];
  //     const updatedSession = {
  //       ...currentSession,
  //       messages: updatedMsgs,
  //     };
  //     const updatedConversation = conversations.map((conv) => {
  //       if (conv.id !== updatedSession.id) {
  //         return {...conv, messages: []};
  //       }
  //       return conv;
  //     });

  //     updateConversationInRedux(updatedConversation);
  //   }
  // }, [newActiveUserId]);

  const FetchCustomerOnlineStatus = async (ids) => {
    const { data } = await api.get('api/customer/online_customer_status/', { params: { objids: ids } });
    setUpdatedOnlineStatus(data.results);
    // console.log("*********** data", setUpdatedOnlineStatus(data.results));
  };

  useEffect(() => {
    if (uniqueCustomersID.length && !runOnlyOnce) {
      polling.set(FetchCustomerOnlineStatus, uniqueCustomersID.join(','), true);
      setRunOnlyOnce(true);
    }
  }, [uniqueCustomersID]);

  // eslint-disable-next-line consistent-return
  const statusHandler = (session) => {
    if (session && updatedOnlineStatus.length) {
      const index = updatedOnlineStatus.findIndex(({ objid }) => objid === session.custId);
      const currentUserStatus = updatedOnlineStatus.findIndex(
        ({ objid }) => objid === activeUser.custId,
      );
      if (currentUserStatus !== -1) {
        setActiveUserOnlineStatus(updatedOnlineStatus[currentUserStatus].is_online);
      }
      if (index !== -1) {
        // console.log('checkStatus', updatedOnlineStatus[index]);
        return updatedOnlineStatus[index].is_online;
      }
    }
  };

  const onChatBackHandler = () => {
    setChatShow(false);
  };

  const onChatRoomHandler = () => {
    setChatShow(true);
  };

  // const getRefereshedInIphone = () => {
  //   let didGalleryOpen = false;
  //   let documentVisibilityStatus = 'visible';
  //   const phoneType = isUserAgentMobile() || '';
  //   document.addEventListener('visibilitychange', () => {
  //     console.log('================');
  //     console.log('in on visibility change event', document.visibilityState, phoneType);
  //     console.log('================');
  //     if (phoneType === 'iphone') {
  //       if (document.visibilityState === 'visible') {
  //       // visibility visible
  //         documentVisibilityStatus = 'visible';
  //         if (!didGalleryOpen) {
  //           window.location.reload();
  //         }
  //       } else if (document.visibilityState === 'hidden') {
  //         // visibility hidden
  //         documentVisibilityStatus = 'hidden';
  //       }
  //     } else {
  //       console.log('android phone');
  //     }
  //   });

  //   window.addEventListener('focus', () => {
  //     console.log('================');
  //     console.log('in on focus event');
  //     if (phoneType === 'iphone') {
  //       didGalleryOpen = false;
  //     } else {
  //       console.log('android phone');
  //     }
  //     console.log('================');
  //   });

  //   window.addEventListener('blur', () => {
  //     console.log('================');
  //     console.log('in on blur event');
  //     if (phoneType === 'iphone') {
  //       if (documentVisibilityStatus === 'hidden') {
  //         didGalleryOpen = true;
  //       }
  //     } else {
  //       console.log('android phone');
  //     }
  //     console.log('================');
  //   });
  // };

  // useEffect(() => {
  //   getRefereshedInIphone();
  // }, [document.visibilityState]);

  useEffect(() => {
    // const updatedConversations = conversations.length > 0 ? JSON.parse(conversations) : [];
    const activeUserExist = conversations?.findIndex(e => e?.iscurrentlyActiveCust === true);
    if (activeUserExist !== -1) {
      const updatedActiveUser = conversations[activeUserExist];
      // localStorage.setItem('activeUser', JSON.stringify(updatedActiveUser));
      setactiveUser(updatedActiveUser);
    }
    // setConversations(updatedConversations);
  }, [conversations]);

  const customerChatLogApiStatus = (apiStatus) => {
    setLoadingListApi(apiStatus);
  };

  // eslint-disable-next-line consistent-return
  const fetchCustomers = async (page = 1) => {
    try {
      const url = `/cfgxmppsessions/list/?character_objid=${charObjid}&phonebook=true&page_size=25&page=${page}&cust2char__gt=-1&exclude_msg_paths=SESSION_CMD|Sys2Cust`;
      const response = await api.get(url);
      const {data} = response;
      if (!cameFromLogsPage) {
        // Append new data to the existing list
        // setCustomersList(prevCustomerList => [...prevCustomerList, ...data.results]);
        updateConversationInRedux([...conversations, ...mapChatLogData(data.results)]);
      }

      // Check if there are more items to load
      if (data.next === null) {
        setHasMoreCustomers(false);
      }
      return data.results;
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const checkActiveSession = async (id = '', convers = []) => {
    if (id) {
      fetchCustomers().then((customers) => {
        let customerPhoneBook = location.state?.customer;
        const allConversations = customers;
        // if (convers.length) {
        const activeUserID = Number(id);
        const url = `/cfgxmppsessions/list/?customer_objid=${activeUserID}&character_objid=${charObjid}&pagesize=1`;
        setDisableResumeChat(true);
        // If user came from logs page, reset the state
        if (cameFromLogsPage) {
          setChatLogsLoading(true);
        }
        checkActiveSessionAvailable(url)
          .then((result) => {
            setDisableResumeChat(false);
            const { data: { results = [] } = {} } = result;
            const isCustomerBlockedByChar = (results.length && results[0].is_blocked_by_char) || false;
            const chatInitiateBlock = (results.length && results[0].chat_initiate_block) || false;
            if (isCustomerBlockedByChar) {
              setIsResumeModalShow(true);
              return;
            }
            if (chatInitiateBlock) {
              setIsChatInitiateBlockModalShow(true);
              return;
            }
            setIsChatInitiatePrimiumModalShow(true);
            if (results.length === 0 && customerPhoneBook) {
              createNewChatRoom(allConversations, customerPhoneBook);
              setChatLogsLoading(false);
              return;
            }
            const activeSesstionId = (results.length && results[0].customer_objid) || 0;
            let currentActiveSesstion = allConversations
              .find(session => session.custId === activeSesstionId);
            if (!currentActiveSesstion) {
            // eslint-disable-next-line prefer-destructuring
              currentActiveSesstion = results[0];
            }
            currentActiveSesstion.iscurrentlyActiveCust = true;
            currentActiveSesstion.active = true;
            currentActiveSesstion.sessionStatus = 'on';
            currentActiveSesstion.unread = 0;
            currentActiveSesstion.premium = 0;
            setactiveUser(currentActiveSesstion);

            // reopen the room
            const updatedConversation = allConversations.map((cust) => {
              if (cust.custId === activeSesstionId) {
                return {...currentActiveSesstion};
              }
              return {...cust, iscurrentlyActiveCust: false};
            });

            if (id && cameFromLogsPage) {
              currentActiveSesstion.sessionStatus = 'on';
              const reorderedConversation = mapChatLogData([
                currentActiveSesstion,
                ...updatedConversation.filter(cust => cust.customer_objid !== activeSesstionId),
              ]);
              updateConversationInRedux(reorderedConversation);
              setChatLogsLoading(false);
              setTimeout(() => {
                const URL = `api/chatmsg/history/?customer2cst_contact_objid=${id}&char2perf_character_objid=${charObjid}&delivery_status=Sent|Read|Undelivered&exclude_msg_paths=SESSION_CMD|Sys2Cust`;
                dispatch(getPastChats(URL, id, reorderedConversation));
              }, 2000);
            }
            if (!cameFromLogsPage) {
              updateConversationInRedux(updatedConversation);
            }
            customerPhoneBook = '';
          // setCameFromLogsPage(false);
          // } Condition Session ENDS
          // create session
          // const updatedConv = convers.filter(session => session.id !== 0);
          // createNewChatRoom(updatedConv);
          })
          .catch((err) => {
            setDisableResumeChat(false);
            setChatLogsLoading(false);
            setCameFromLogsPage(false);
            console.log(err);
          });
      // }
      });
    } else {
      const url = `/cfgxmppsessions/list/?customer_objid=${activeUser?.custId}&character_objid=${charObjid}&pagesize=1`;
      checkActiveSessionAvailable(url)
        .then((result) => {
          setDisableResumeChat(false);
          const { data: { results = [] } = {} } = result;
          const isCustomerBlockedByChar = (results.length && results[0].is_blocked_by_char) || false;
          const chatInitiateBlock = (results.length && results[0].chat_initiate_block) || false;
          if (isCustomerBlockedByChar) {
            setIsResumeModalShow(true);
            return;
          }
          if (chatInitiateBlock) {
            setIsChatInitiateBlockModalShow(true);
            return;
          }
          setIsChatInitiatePrimiumModalShow(true);
          const activeSesstionId = (results.length && results[0].customer_objid) || 0;

          let CAS = conversations // CAS = Current Active Session
            .find(session => session.custId === activeSesstionId);
          if (!CAS) {
            // eslint-disable-next-line prefer-destructuring
            CAS = results[0];
          }
          setactiveUser(CAS);
          CAS.iscurrentlyActiveCust = true;
          // CAS.active = true;
          CAS.sessionStatus = 'pending';
          CAS.unread = 0;
          CAS.premium = 0;

          // reopen the room
          const updatedConversation = conversations.map((cust) => {
            if (cust.custId === activeSesstionId) {
              return {...CAS};
            }
            return {...cust, iscurrentlyActiveCust: false};
          });
          updateConversationInRedux(updatedConversation);
        })
        .catch((err) => {
          setDisableResumeChat(false);
          setChatLogsLoading(false);
          setCameFromLogsPage(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    // Initial fetch
    // fetchCustomers().then((data) => {
    //   updateConversationInRedux(mapChatLogData(data));
    // });
    if (!location.state?.id) {
      fetchCustomers();
    }
  }, [location.state]);

  const nextCustomers = () => {
    if (cameFromLogsPage) {
      setCameFromLogsPage(false);
    }
    setPageSize((prevPage) => {
      const nextPage = prevPage + 1;
      fetchCustomers(nextPage);
      return nextPage;
    });
  };

  useEffect(() => {
    if (charObjid && charObjid !== 0) {
      const presenceUrl = `/perfcharactergroup/list/?objid2perf_character=${charObjid}&group=PRESENCE_SMS|PRESENCE_VOICE|PRESENCE_VIDEO`;
      getPresenceUser(presenceUrl);
    }
    // close all open rooms when component unmount
    // return () => {
    //   const updatedConversation = conversations.map((conv) => {
    //     if (conv.iscurrentlyActiveCust) {
    //       return {
    //         ...conv,
    //         iscurrentlyActiveCust: false,
    //       };
    //     }
    //     return conv;
    //   });
    //   updateConversationInRedux(updatedConversation);
    // };
  }, []);

  useEffect(() => {
    const id = location.state?.id;
    if (id && cameFromLogsPage) {
      // getCustomerChatLog(customersList, customerChatLogApiStatus, id, convers => checkActiveSession(id, convers));
      checkActiveSession(id);
    }
    // else {
    //   !id && getCustomerChatLog(customersList, customerChatLogApiStatus);
    // }
  }, [pageSize, location.state, cameFromLogsPage]);

  // useEffect(() => {
  //   const id = location.state?.id;
  //   if (cameFromLogsPage) {
  //     checkActiveSession(id);
  //   }
  // }, [location.state]);

  useEffect(() => {
    const id = location.state?.id;
    if (!hasEffectRun && conversations && conversations.length && !id) {
      const URL = `api/chatmsg/history/?customer2cst_contact_objid=${conversations[0].custId}&char2perf_character_objid=${charObjid}&delivery_status=Sent|Read|Undelivered&exclude_msg_paths=SESSION_CMD|Sys2Cust`;
      dispatch(getPastChats(URL, conversations[0].custId, conversations));
      setHasEffectRun(true);
    }
    // Fetch data when the component mounts
  }, [currentPage, dispatch, conversations, hasEffectRun, location.state, activeUser]);

  const fetchMoreData = (isScroll) => {
    // Fetch more data when the infinite scroll component triggers this function
    const customer = newActiveUserId || activeUser;
    if (customer) {
      const activeConversation = conversations.find(el => el.custId === customer.custId);
      if (activeConversation.messages.length < totalCount && !loading) {
        setHasMore(true);
        dispatch(setCurrentPage(currentPage + 1));
        const URL = `api/chatmsg/history/?customer2cst_contact_objid=${customer.custId}&char2perf_character_objid=${charObjid}&delivery_status=Sent|Read|Undelivered&exclude_msg_paths=SESSION_CMD|Sys2Cust&page=${isScroll ? currentPage + 1 : currentPage}`;
        dispatch(getPastChats(URL, customer.custId, conversations, setactiveUser, true));
      } else {
        setHasMore(false);
      }
    }
  };

  const debouncedFetchMoreData = debounce(fetchMoreData, 1000);

  useEffect(() => () => clearTimeout(debouncedFetchMoreData), [debouncedFetchMoreData]);

  const getActiveCustomer = (sessionIDS) => {
    const { sessionId, objid, custId } = sessionIDS;
    dispatch(setCurrentPage(1));
    if (sessionIDS) {
      if (sessionIDS.custId) {
        const URL = `api/chatmsg/history/?customer2cst_contact_objid=${custId}&char2perf_character_objid=${charObjid}&delivery_status=Sent|Read|Undelivered&exclude_msg_paths=SESSION_CMD|Sys2Cust&page=1`;
        dispatch(getPastChats(URL, sessionIDS.custId, conversations, setactiveUser, false));
        // Read msg Api
      }

      // updateConversationInRedux(updatedConversation);
    }
  };

  const setCustomerListOrder = (sessionIds) => {
    if (activeUser?.custId !== sessionIds.custId) {
      const currentSession = conversations.find(conv => conv.custId === sessionIds.custId);
      if (currentSession && currentSession.custId) {
        const updatedConversation = conversations.map((conv) => {
          if (conv.custId === sessionIds.custId) {
            setactiveUser({...currentSession, iscurrentlyActiveCust: true});
            return {...conv, iscurrentlyActiveCust: true, unread: 0};
          }
          if (conv && conv.iscurrentlyActiveCust) {
            return {
              ...conv,
              iscurrentlyActiveCust: false,
            };
          }
          return { ...conv, messages: [] };
        });
        console.log('Count 2');
        updateConversationInRedux(updatedConversation);
      }

      // if (location && location.state && location.state.customer) {
      //   console.log('customer');
      //   const state = { ...location.state };
      //   delete state.customer;
      //   history.replace({ ...location, state });
      // }
      setCameFromLogsPage(false);
      getActiveCustomer(sessionIds);
      setNewActiveUserId(sessionIds);
      setIsRoomChanged(sessionIds);
    }
  };

  const getPrivateMedia = (newuUrl = null) => {
    let url = `/perfcharacterproduct/list/?ordering=-objid&type=private&is_published=1&page=1&page_size=15&isactive=1&character=${charObjid}`;
    if (newuUrl) {
      url = newuUrl;
    }
    getPrivatePhotos(url);
  };

  const getChatMediaPhotos = () => {
    const url = `/perfcharacterproduct/list/?ordering=-objid&type=chat_image&is_published=1&isactive=1&character=${charObjid}`;
    getPrivatePhotos(url)
      .then((response) => {
        getChatMediaPhotosState(response?.value?.data?.results);
      })
      .catch((err => console.log(err)));
  };

  const getChatMediaVideos = () => {
    const url = `/perfcharacterproduct/list/?ordering=-objid&type=chat_video&is_published=1&isactive=1&character=${charObjid}`;
    getPrivatePhotos(url)
      .then((response) => {
        getChatMediaVideosState(response?.value?.data?.results);
      })
      .catch((err => console.log(err)));
  };


  // this code is commented because of this photos and videos apis calling on chat
  // useEffect(() => {
  //   getChatMediaPhotos();
  // }, []);

  // useEffect(() => {
  //   getChatMediaVideos();
  // }, []);


  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };

  const askNotificationPermission = () => {
    // function to actually ask the permissions
    const handlePermission = () => {
      // set the button to shown or hidden, depending on what the user answers
      if (Notification.permission === 'denied' || Notification.permission === 'default') {
        setChatNotificationPermission(Notification.permission);
      } else {
        setChatNotificationPermission(Notification.permission);
      }
    };

    // Let's check if the browser supports notifications
    if (('Notification' in window)) {
      if (checkNotificationPromise()) {
        Notification.requestPermission()
          .then((permission) => {
            handlePermission(permission);
          });
      } else {
        Notification.requestPermission((permission) => {
          handlePermission(permission);
        });
      }
    } else {
      console.log('This browser does not support notifications.');
    }
  };

  useEffect(() => {
    askNotificationPermission();
    window.scrollTo(0, 0);
  }, []);

  const updateConversations = (session = {}, sessionId = 0, sessionObjId = 0) => {
    let correctActiveSession = session;
    if (activeUser.id === 0 && sessionId && sessionObjId) {
      correctActiveSession = {
        ...session,
        id: sessionId,
        objid: sessionObjId,
      };
    }
    const updatedConversation = conversations.map((conv) => {
      if (conv.id === session.id) {
        return correctActiveSession;
      }
      return conv;
    });
    updateConversationInRedux(updatedConversation);
  };
  const addMessage = (msg, session = {}) => {
    const { text, currentUTC } = msg;
    let currentActiveSession = {};
    const messages = session.messages || [];
    messages.push(msg);
    const lastMessageTime = new Date(currentUTC).getTime();
    const lastMsg = text;
    currentActiveSession = {
      ...session,
      messages,
      lastMessageTime,
      lastMsg,
    };
    updateConversations(currentActiveSession);
  };

  const endPerformerSession = (data) => {
    let sessionEndConv = conversations.find(conv => conv.id === data.session_id);
    sessionEndConv = {
      ...sessionEndConv,
      sessionStatus: 'off',
    };
    updateConversations(sessionEndConv);
    // setConversations(updatedConversation);
  };

  const updateMessageStatus = (
    response = {},
    apiStatus = false,
    performerContinueMsg,
    newMsg,
    isForwardMedia = false,
    correctActiveSessions = {},
  ) => {
    const {
      message_txt: msgLast, message_id: messageId,
      media = '', session_id: sessionId, objid,
      text, session2cfg_xmpp_sessions_objid: sessionObjId,
    } = response;

    // eslint-disable-next-line
    if (isForwardMedia) {
      const messages = correctActiveSessions.messages || [];
      messages.push({
        ...newMsg,
        text: media !== 'error' ? 'Sent Photo' : 'Error in image upload',
        media: media !== 'error' ? newMsg.media : '',
        chat_status: apiStatus ? 'Sent' : 'Failed',
        objid,
        mediaType: newMsg.mediaType,
        videoId: newMsg.videoId,
        sessionObjId,
        sessionId,
      });
      const correctActiveSession = {
        ...correctActiveSessions,
        messages: [...messages],
        lastMsg: msgLast || text,
        performerContinueMsgCount: performerContinueMsg,
      };
      updateConversations(correctActiveSession);
    } else {
      let correctActiveSession = activeUser;
      const messages = activeUser.messages || [];
      const newMessages = messages.map((msg) => {
        let toReturn = {
          ...msg,
        };

        if (msg.currentUTC === messageId) {
          if (!newMsg?.media) {
            toReturn = {
              ...toReturn,
              text: msg.text,
              chat_status: apiStatus ? 'Sent' : 'Failed',
              objid,
              sessionObjId,
              sessionId,
            };
          }
          if (newMsg?.media) {
            const updatedMedia = media || URL.createObjectURL(newMsg.media);
            toReturn = {
              ...toReturn,
              text: media !== 'error' ? 'Sent Photo' : 'Error in image upload',
              media: media !== 'error' ? updatedMedia : '',
              chat_status: apiStatus ? 'Sent' : 'Failed',
              objid: Number(objid + 1),
              mediaType: newMsg.mediaType,
              videoId: newMsg.videoId,
              sessionObjId,
              sessionId,
            };
          }
        }
        return toReturn;
      });
      correctActiveSession = {
        ...correctActiveSession,
        messages: newMessages,
        lastMsg: msgLast || text,
        performerContinueMsgCount: performerContinueMsg,
      };

      updateConversations(correctActiveSession, parseInt(sessionId, 10), sessionObjId);
    }
    // setConversations(newConversations);
    if (text === 'over') {
      endPerformerSession(response);
    }
  };

  // Media Type checking
  const getMediaType = (selectedMediaObj) => {
    const fileType = selectedMediaObj;
    const extension = fileType.split('.').pop();
    const extensionUpper = extension.toUpperCase();
    const allowVideoTypes = ['WEBM', 'MP4', 'M4P', 'M4V', 'AVI', 'WMV', 'MOV', '3GP', 'QTFF', 'QT', 'QUICKTIME'];
    const allowImageTypes = ['TIFF', 'JPG', 'JPEG', 'PNG', 'BMP', 'JFIF'];
    const allowAudioTypes = ['MP3', 'WEBM', 'M4a', 'M4A'];

    if (allowVideoTypes.includes(extensionUpper)) {
      // video
      return 'video';
    }
    if (allowImageTypes.includes(extensionUpper)) {
      // image
      return 'image';
    }

    if (allowAudioTypes.includes(extensionUpper)) {
      // audio
      return 'audio';
    }
    return '';
  };

  // api for host can report/block customer even session is ended
  const blockedInactiveSessionCustomer = (value) => {
    const trimedVal = value.slice(1);
    const newFormData = new FormData();
    newFormData.append('customer_id', activeUser.custId);
    newFormData.append('character_id', charObjid);
    newFormData.append('flag', trimedVal);
    api.post('/reportcustomer/', newFormData);
  };

  const SubmitForwardRequest = async (
    msgContent,
    customerId,
    productID) => {
    const {
      media = '',
      productId = 0, mediaType = '', videoId = '', audioURL = '',
      // eslint-disable-next-line camelcase
      newProductID, text, creditValue, defaultCreditsBool = false, objid, product_price,
    } = msgContent;

    const currentUTC = new Date().toUTCString();
    const customerchanneltype = activeUser.customerchanneltype || 'WEBCHAT';
    const msg = new FormData();
    const newMsg = {
      direction: 'out',
      audioURL,
      // chat_status: 'Sending',
      type: 'Char2Cust',
      currentUTC,
      text,
      media,
      mediaType,
      videoId,
      productId,
      newProductID,
      creditValue,

    };
    msg.append('character_objid', charObjid);
    msg.append('message_id', currentUTC);
    msg.append('cust_objid', customerId);
    msg.append('customer_channel_id', jid);
    msg.append('delivery_channel', customerchanneltype);
    msg.append('customer_channel_type', customerchanneltype);
    msg.append('msg_path', 'SESSION_CMD');
    // msg.append('body', `{"product_objid": ${productID}}`);
    // eslint-disable-next-line camelcase
    msg.append('body', `{"product_objid": ${productID}, "product_price": ${creditValue || product_price}, "default_price": ${defaultCreditsBool}}`);


    try {
      const response = await createMessageFunction(msg);
      const correctActiveSession = conversations.find(e => e.custId === customerId) || {};
      updateMessageStatus(response.value.data, true, true, newMsg, true, correctActiveSession);
      if (response.value.status !== 201) {
        // Handle non-successful response (e.g., HTTP error status)
        throw new Error(`API request failed with status ${response.status}`);
      }
    } catch (error) {
      // Handle errors without breaking the loop
      console.error(`Error for customer ${customerId}: ${error.message}`);
      updateMessageStatus({
        ...newMsg,
        session_id: activeUser.id,
        message_id: currentUTC,
      }, false, true);
    }
    return false;
  };

  const sendMessage = (msgContent, isPremiumChatReq = false) => {
    const {
      text = '', media = '', isCallAddMessage = true,
      productId = 0, creditValue = 0, mediaType = '', videoId = '', audioURL = '',
      defaultCreditsBool = false, objid, chatBotMsgID,
      newProductID,
    } = msgContent;

    const currentUTC = new Date().toUTCString();
    const customerchanneltype = activeUser.customerchanneltype || 'WEBCHAT';
    const msgPath = isPremiumChatReq || media ? 'SESSION_CMD' : 'Char2Cust';
    const msg = new FormData();
    msg.append('character_objid', charObjid);
    msg.append('message_id', currentUTC);
    msg.append('cust_objid', activeUser.custId);
    msg.append('customer_channel_id', jid);
    msg.append('delivery_channel', customerchanneltype);
    msg.append('customer_channel_type', customerchanneltype);
    msg.append('msg_path', msgPath);

    if (localStorage.getItem('chatBotMsgID')) {
      msg.append('chatbotmsg_id', localStorage.getItem('chatBotMsgID'));
      localStorage.removeItem('chatBotMsgID');
    }

    if (chatBotMsgID) {
      msg.append('chatbotmsg_id', chatBotMsgID);
    }

    let newMsg = {
      direction: 'out',
      text,
      media: '',
      audioURL,
      chat_status: 'Sending',
      type: 'Char2Cust',
      currentUTC,
      productId,
      newProductID,
    };

    if (productId) {
      // msg.append('body', `ProductId:${productId}|${creditValue}`);
      msg.append('body', `{"product_objid": ${objid || productId}, "product_price": ${creditValue}, "default_price": ${defaultCreditsBool}}`);

      newMsg = {
        ...newMsg,
        text: 'Loading',
        media,
        creditValue,
        mediaType,
        videoId,
        productId,
        newProductID,
      };
    } else {
      msg.append('body', text);
    }

    const performerContinueMsg = activeUser.performerContinueMsgCount + 1;
    const session = {
      ...activeUser,
      lastMsg: text,
      performerContinueMsgCount: performerContinueMsg,
    };

    if (isPremiumChatReq) {
      let updatedPremiumChatUser = {};
      if (text === 'Billable') {
        updatedPremiumChatUser = {
          ...activeUser,
          premium: 9,
        };
      } else {
        updatedPremiumChatUser = {
          ...activeUser,
          premium: 0,
        };
      }
      setactiveUser(updatedPremiumChatUser);
    }

    if (!isPremiumChatReq && isCallAddMessage) {
      addMessage(newMsg, session);
    }

    // const data = imagefile ? formData : msg;
    createMessageFunction(msg)
      .then((response) => {
        if (!isPremiumChatReq && isCallAddMessage) {
          setActiveSessionID(response.value.data.session2cfg_xmpp_sessions_objid);
          localStorage.setItem('sessionID', JSON.stringify(Number(response.value.data.session_id)) || '');
          updateMessageStatus(response.value.data, true, performerContinueMsg, newMsg);
          setIsSessionExpired(false);
        } else {
          setActiveSessionID(response.value.data.session2cfg_xmpp_sessions_objid);
          localStorage.setItem('sessionID', JSON.stringify(Number(response.value.data.session_id)) || '');
        }

        // if (!isCallAddMessage) {
        //   const data = {
        //     ...chatValidationData,
        //     floodedMsg: true,
        //   };
        //   setValidateChatPermission(data);
        // }
      })
      .catch((err) => {
        console.log(`Message error: ${err.message}`);
        if (!isPremiumChatReq && isCallAddMessage) {
          updateMessageStatus({
            ...newMsg,
            session_id: activeUser.id,
            message_id: currentUTC,
          }, false, performerContinueMsg);
        } else {
          // handle premium chat req. fail
          const updatedPremiumChatUser = {
            ...activeUser,
            premium: 0,
          };
          setactiveUser(updatedPremiumChatUser);
        }
        // if (imagefile) {
        //   this.updateMessageStatus({ ...msg, media: 'error' }, false);
        // }
      });
  };


  const getOneTimeUploadUrl = async (uId) => {
    console.log('getOneTimeUploadUrl called');
    return `https://upload.videodelivery.net/${uId}`;
  };

  const videoMediaUploadHandler = async (uid, media, newMsg) => {
    const oneTimeUploadUrl = await getOneTimeUploadUrl(uid);
    const formData = new FormData();
    formData.append('file', media);
    const uploadResult = await fetch(oneTimeUploadUrl, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    });

    let intervalConstant = '';
    let timeout = '';
    intervalConstant = setInterval(() => {
      api.get(`/perfcharacterproduct/streamstatus/?video_id=${newMsg.videoId}`)
        .then((res) => {
          console.log('response', res);
          const { status = false } = res?.data;
          if (status === true) {
            sendMessage(newMsg, false);
            clearInterval(intervalConstant);
            clearTimeout(timeout);
            setIsProgress(100);
            setTimeout(() => {
              setVideoStatuLoading(false);
            }, 200);
          }
        })
        .catch((err) => {
          clearInterval(intervalConstant);
          clearTimeout(timeout);
          setIsProgress(100);
          setTimeout(() => {
            setVideoStatuLoading(false);
          }, 200);
        });
    }, 2000);
    console.log('uploadResult', uploadResult);

    // Set up the timeout to clear the interval after 2 minutes (120 seconds)
    timeout = setTimeout(() => {
      clearInterval(intervalConstant);
      setIsProgress(100);
      setTimeout(() => {
        setVideoStatuLoading(false);
      }, 200);
      toast.error('Something went wrong.');
      console.log('Interval cleared after 2 minutes');
    }, 120000); // 2 minutes in milliseconds
  };

  const favUnFavHandlerFun = (isFavUnFav) => {
    toast.dismiss(favoToastID);
    if (isFavUnFav) {
      const url = '/api/xrefcustomercharacter/create/';
      const formData = new FormData();
      formData.append('perf_character_objid', charObjid);
      formData.append('cst_contact_objid', activeUser.custId);
      formData.append('type', 'favorite_customer');

      api.post(url, formData).then((response) => {
        favoToastID = toast.success(`${activeUser?.title} is added to your favorites`);
        const updatedConversation = conversations.map((conv) => {
          if (conv.id === activeUser.id) {
            return {
              ...activeUser,
              isFavCustomer: true,
            };
          }
          return conv;
        });
        updateConversationInRedux(updatedConversation);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      toast.dismiss();
      const url = '/api/removecustomercharacterrelation/';
      const formData = new FormData();
      formData.append('char_objid', charObjid);
      formData.append('cust_objid', activeUser.custId);
      formData.append('type', 'favorite_customer');
      api.post(url, formData).then((response) => {
        favoToastID = toast.success(`${activeUser?.title} is removed from your favorites`);

        const updatedConversation = conversations.map((conv) => {
          if (conv.id === activeUser.id) {
            return {
              ...activeUser,
              isFavCustomer: false,
            };
          }
          return conv;
        });
        updateConversationInRedux(updatedConversation);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  // call for media msg
  const sendMedia = (mediaMsg) => {
    // get media from system
    const {
      media = null, creditValue = 0, mediaType = '', defaultCreditsBool = '', duration = '',
    } = mediaMsg;
    let type;
    // check file type
    if (getMediaType(mediaMsg.media.name) === 'video') {
      type = 'chat_video';
    } else if (getMediaType(mediaMsg.media.name) === 'image') {
      type = 'chat_image';
    } else {
      type = 'chat_audio';
    }
    // const type = (getMediaType(mediaMsg.media.name) === 'video') ? 'chat_video' : 'chat_image';
    const mediaFormData = new FormData();
    mediaFormData.append('character', charObjid); // character objid
    mediaFormData.append('description', activeSessionID || activeUser?.objid); // current session objid
    mediaFormData.append('price', creditValue); // price
    mediaFormData.append('product_name', 'chat_upload'); // media name
    mediaFormData.append('type', type); // media type
    // mediaFormData.append('file', media); // file
    if (getMediaType(mediaMsg.media.name) === 'video') {
      mediaFormData.append('duration_seconds', Math.floor(duration)); // video duration
    } else {
      mediaFormData.append('file', media); // file
    }
    setVideoStatuLoading(true);
    setIsProgress(0);

    // call product create api
    if (activeUser?.objid) {
      createNewMedia(mediaFormData)
        .then((response) => {
          // eslint-disable-next-line camelcase
          const { objid = 0, product_thumb_url, description } = response?.value?.data;
          if (objid) {
            // call create msg api
            const msg = {
              text: '',
              media,
              productId: Number(objid),
              newProductID: Number(objid),
              creditValue,
              mediaType,
              defaultCreditsBool,
              objid,
              // audioURL: product_thumb_url
            };
            if ((getMediaType(mediaMsg.media.name) === 'video') && objid) {
              const {
                video_id: videoId = 0,
                upload_url_data: { results: { uid } },
              } = response?.value?.data;
              console.log('uid', uid);
              if (videoId) {
                const newMsg = {
                  ...msg,
                  videoId,
                  newProductID: Number(objid),
                  objid,
                };
                videoMediaUploadHandler(uid, media, newMsg);
              }

              // const intervalConstant = setInterval(() => {
              //   api.get(`/perfcharacterproduct/list/?objid=${objid}`)
              //     .then((res) => {
              //       const { video_id: videoId = 0 } = res?.data?.results[0];
              //       if (videoId) {
              //         const newMsg = {
              //           ...msg,
              //           videoId,
              //         };
              //         sendMessage(newMsg, false);
              //         clearInterval(intervalConstant);
              //         setIsProgress(100);
              //         setTimeout(() => {
              //           setVideoStatuLoading(false);
              //         }, 200);
              //       }
              //     })
              //     .catch((err => console.log(err)));
              // }, 3000);
            } else if (getMediaType(mediaMsg.media.name) === 'audio') {
              // in case of audio
              sendMessage({...msg, audioURL: product_thumb_url}, false);
              setIsProgress(100);
              setTimeout(() => {
                setVideoStatuLoading(false);
              }, 200);
            } else {
              // in case of image
              sendMessage(msg, false);
              setIsProgress(100);
              setTimeout(() => {
                setVideoStatuLoading(false);
              }, 200);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 400 && error.response.data.message[0] === 'Invalid session objid') {
            setIsSessionExpired(true);
          }
          setIsProgress(0);
          setVideoStatuLoading(false);
        });
    }
  };

  const renderResumeModal = () => (
    <CustomModal
      body="You can not chat, you blocked this customer."
      buttonText="Ok"
      footer="Dismiss"
      footerLink={() => setIsResumeModalShow(false)}
      open={isResumeModalshow}
      onClick={() => setIsResumeModalShow(false)}
      toggle={() => setIsResumeModalShow(false)}
    />
  );

  const renderChatInitiateModal = () => (
    <ChatInitiateBlockModal
      header="Not Enabled"
      body="This customer has not enabled you for initiating a chat."
      buttonText="Ok"
      footer="Dismiss"
      footerLink={() => setIsChatInitiateBlockModalShow(false)}
      open={isChatInitiateBlockModalShow}
      onClick={() => setIsChatInitiateBlockModalShow(false)}
      toggle={() => setIsChatInitiateBlockModalShow(false)}
    />
  );
  const renderPrimiumModal = () => (
    <ChatInitiatePrimiumModal
      header="Note"
      body="Host initiated session will automatically convert to premium mode when customer accept it"
      buttonText="Ok"
      footer="Dismiss"
      footerLink={() => setIsChatInitiatePrimiumModalShow(false)}
      open={isChatInitiatePrimiunModalShow}
      onClick={() => setIsChatInitiatePrimiumModalShow(false)}
      toggle={() => setIsChatInitiatePrimiumModalShow(false)}
    />
  );

  if (videoStatuLoading) {
    return (
      <div className="video_status_loader">
        <i className="fa fa-spinner fa-spin" style={{ color: '#fd7663', fontSize: '30px', marginBottom: '10px' }} />
        <p>
          Please wait while the media is uploading...
          (
          {isProgress}
          %
          )
        </p>
        <progress value={isProgress} max="100" />
      </div>
    );
  }

  return (
    <>
      {renderResumeModal()}
      {renderChatInitiateModal()}
      {renderPrimiumModal()}
      <Row className="mt-3">
        {chatLogsLoading && (<div className="chatLogsLoading"><LoadingSpinner /></div>)}
        <Col sm={12}>
          <h6 className="chatHeading">Chat</h6>
        </Col>
      </Row>
      <Row className="ar_host_chat_frame_box">
        <Col lg={4} xs={12} className={chatShow ? 'pr-0 customerlist-main-wrap dis-none' : 'pr-0 customerlist-main-wrap'}>
          <CustomerList
            conv={conversations}
            setCustomerListOrder={sessionIds => setCustomerListOrder(sessionIds)}
            nextChatLog={nextCustomers}
            hasMoreCustomers={hasMoreCustomers}
            charObjid={charObjid}
            getCustomerChatLog={
            (url, conv) => getCustomerChatLog(url, conv, customerChatLogApiStatus)
          }
            loadingListApi={loadingListApi}
            chatLogsLoading={chatLogsLoading}
            onChatRoomHandler={onChatRoomHandler}
            onlineStatusHandler={statusHandler}
            updatedOnlineStatus={updatedOnlineStatus}
            activeUser={activeUser}
          />
        </Col>
        <Col lg={8} xs={12} className={chatShow ? 'pl-0 all-convo-main-wrap.dis-block' : 'pl-0 all-convo-main-wrap'}>
          <AllConversation
            activeUser={activeUser}
            fetchMoreData={debouncedFetchMoreData}
            sendMessage={sendMessage}
            sendMedia={sendMedia}
            chatValidationData={chatValidationData}
            getPrivateMedia={getPrivateMedia}
            privateProductList={privateProductList}
            next={next}
            privateMediaCount={count}
            chatMsgLoading={chatMsgLoading}
            minImagePurchaseCredits={minImagePurchaseCredits}
            minVideoPurchaseCredits={minVideoPurchaseCredits}
            minAudioPurchaseCredits={minAudioPurchaseCredits}
            infiniteloading={loading}
            performerMediaPrices={performerMediaPrices}
            onChatBackHandler={onChatBackHandler}
            checkActiveSessionFun={checkActiveSession}
            resumeBtndisabled={disableResumeChat}
            getChatMediaPhotosState={chatMediaPhotosState}
            getChatMediaVideosState={chatMediaVideosState}
            getChatMediaPhotosFun={getChatMediaPhotos}
            getChatMediaVideosFun={getChatMediaVideos}
            createCustomerReviewFun={createCustomerReviewFun}
            isRoomToggled={isRoomChanged}
            activeUserOnlineStatus={activeUserOnlineStatus}
            blockedInactiveSessionCustomer={blockedInactiveSessionCustomer}
            charObjid={charObjid}
            newActiveUserId={newActiveUserId}
            SubmitForwardRequest={SubmitForwardRequest}
            hasMore={hasMore}
            activeSessionID={activeSessionID}
            isSessionExpired={isSessionExpired}
            setIsSessionExpired={setIsSessionExpired}
            favUnFavHandlerFun={favUnFavHandlerFun}
            onlineStatusHandler={statusHandler}
            minReqMsgToShareMedia={minReqMsgToShareMedia}
          />
        </Col>
      </Row>
    </>
  );
};

Chat.defaultProps = {
  conversations: [],
  chatValidationData: {},
  privateProductList: [],
  next: null,
  count: 0,
  // chatSessionData: [],
  minImagePurchaseCredits: '',
  minVideoPurchaseCredits: '',
  minAudioPurchaseCredits: '',
  performerMediaPrices: '',
  minMessageToInitaiteChat: '',
  minReqMsgToShareMedia: '',
};

Chat.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  conversations: PropTypes.instanceOf(Array),
  createMessageFunction: PropTypes.func.isRequired,
  updateConversationInRedux: PropTypes.func.isRequired,
  // getSessionMsgs: PropTypes.func.isRequired,
  createNewMedia: PropTypes.func.isRequired,
  getCustomerChatLog: PropTypes.func.isRequired,
  jid: PropTypes.string.isRequired,
  charObjid: PropTypes.number.isRequired,
  getPrivatePhotos: PropTypes.func.isRequired,
  chatValidationData: PropTypes.instanceOf(Object),
  privateProductList: PropTypes.instanceOf(Array),
  next: PropTypes.string,
  count: PropTypes.bool,
  setChatNotificationPermission: PropTypes.func.isRequired,
  // chatSessionData: PropTypes.instanceOf(Array),
  // sendMessage: PropTypes.func.isRequired,
  minImagePurchaseCredits: PropTypes.string,
  minVideoPurchaseCredits: PropTypes.string,
  minAudioPurchaseCredits: PropTypes.string,
  performerMediaPrices: PropTypes.string,
  getPresenceUser: PropTypes.func.isRequired,
  checkActiveSessionAvailable: PropTypes.func.isRequired,
  minMessageToInitaiteChat: PropTypes.string,
  // isTier2: PropTypes.bool.isRequired,
  createCustomerReviewFun: PropTypes.func.isRequired,
  minReqMsgToShareMedia: PropTypes.string,
};

export default withRouter(Chat);
